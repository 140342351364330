import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/redux/store";
import navigation from "@/i18n/json/navigation";
import {INavigationItem} from "@/components/navigation/interfaces/INavigationItem";

const initialState: { navigation: INavigationItem[]; mobileMenuIsOpen: boolean } = { navigation: navigation.en, mobileMenuIsOpen: false };

const navigationSlice = createSlice({
    name: "navigation",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setNavigationItemState: (state, action: PayloadAction<INavigationItem[]>) => {
            state.navigation = action.payload;
        },
        toggleMobileNavigationMenu: (state, action: PayloadAction<boolean>) => {
            state.mobileMenuIsOpen = action.payload;
        },
    },
});

export const { setNavigationItemState, toggleMobileNavigationMenu } = navigationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getNavigationState = (state: RootState) => state.navigation.navigation;
export const getMobileMenuIsOpen = (state: RootState) => state.navigation.mobileMenuIsOpen;

export default navigationSlice.reducer;
