import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/redux/store";

interface ILocale {
    locale: string;
}

const initialState: ILocale = {
    locale: "en",
};

const localeSlice = createSlice({
    name: "locale",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<string>) => {
            state.locale = action.payload;
        },
    },
});

export const { setLocale } = localeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getLocale = (state: RootState) => state.locale.locale;

export default localeSlice.reducer;
