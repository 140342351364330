import type { AppProps } from "next/app";
import store from "@/redux/store";
import { Provider } from "react-redux";
import "@/i18n/config";
// Website styles
import "../../styles/globals.scss";
import GoogleTagManager from "@/components/google-tag-manager";
import HighPerformanceScripts from "@/components/high-performance-scripts";
import "react-slideshow-image/dist/styles.css";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <GoogleTagManager />
      <HighPerformanceScripts />
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </>
  );
}
export default MyApp;
