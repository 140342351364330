// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        environment: "production",
        dsn: SENTRY_DSN || "https://585146e84cde4e9ab064f7db75dc0bc6@o100489.ingest.sentry.io/228454",
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        allowUrls: [/https?:\/\/((cdn|www|.*)\.)?hearxgroup\.com/],
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
