import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/redux/store";

export interface IBilling {
    activeCurrency: string;
    thirdPartyCookieSupport: boolean;
    token: boolean;
}

export const initialState = { activeCurrency: "ZAR", thirdPartyCookieSupport: false, token: false };

const billingSlice = createSlice({
    name: "billing",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCurrency: (state, action: PayloadAction<string>) => {
            state.activeCurrency = action.payload;
        },
    },
});

export const { setCurrency } = billingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getBillingState = (state: RootState) => state.billing;
export const getCurrency = (state: RootState) => state.billing.activeCurrency;

export default billingSlice.reducer;
