import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/redux/store";
import navigation from "@/i18n/json/navigation";
import {INavigationItem} from "@/components/navigation/interfaces/INavigationItem";

const initialState: { navigation: INavigationItem[] } = { navigation: navigation.en[0].subitems ? navigation.en[0].subitems : [] };

const productNavigationSlice = createSlice({
    name: "product-navigation",
    initialState,
    reducers: {
        setProductNavigationItemState: (state, action: PayloadAction<INavigationItem[]>) => {
            state.navigation = action.payload;
        },
    },
});

export const { setProductNavigationItemState } = productNavigationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getProductNavigationState = (state: RootState) => state.productNavigation.navigation;

export default productNavigationSlice.reducer;
