import {configureStore} from "@reduxjs/toolkit";
import localeReducer from "@/redux/slices/locale";
import navigationReducer from "config/redux/slices/navigation";
import productNavigationReducer from "./slices/product-navigation";
import billingReducer from "./slices/billing";
import cookiesReducer from "./slices/cookies";

const store = configureStore({
    reducer: {
        locale: localeReducer,
        navigation: navigationReducer,
        productNavigation: productNavigationReducer,
        billing: billingReducer,
        cookies: cookiesReducer
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
