import Script from "next/script";
/* 
This component provides the lazy loading of high performance scripts that 
executes on the start of the website journey
*/
const HighPerformanceScripts: React.FC = () => {
    return (
        <>
            <Script>
                {/* Initializing the big JS scripts after 5 seconds - this can be adjusted */}
                {`
					document.addEventListener('DOMContentLoaded', () => {
						/** init gtm after 5000 seconds - this could be adjusted */
						setTimeout(() => {
							initReCAPTCHA();
						}, 5000);
					});
				`}

                {/* Client interaction events that can trigger the high-performance scripts before the 5 second interval has passed	*/}
                {`
					document.addEventListener('scroll', initHighPerformanceScripts);
					document.addEventListener('mousemove', initHighPerformanceScripts);
					document.addEventListener('touchstart', initHighPerformanceScripts);
				`}

                {/* A function that initializes the high-performance scripts => triggered by the client interaction events */}
                {`				
					function initHighPerformanceScripts (event) {
						initReCAPTCHA();
						event.currentTarget.removeEventListener(event.type, initHighPerformanceScripts); // remove the event listener that got triggered
					}
				`}

                {/* A function that creates scripts */}
                {`		
					function createScript(onLoad, src) {
						const script = document.createElement('script');
						script.type = 'text/javascript';
						script.async = true;
						script.onload = onLoad;
						script.src = src
						
						document.head.appendChild(script);
					}
				`}


                {/* reCAPTCHA initialization */}
                {`
					function initReCAPTCHA() {
						if(window.reCAPTCHADidInit) {
							return false;
						}
						window.reCAPTCHADidInit = true; // flag to ensure script does not get added to DOM more than once.
						createScript(() => { 								
							console.log("reCAPTCHA initialized")
						},"https://www.google.com/recaptcha/api.js")
						createScript(() => { 								
							console.log("reCAPTCHA rendered")
						},"https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}")
					}
				`}
            </Script>

        </>
    );
};

export default HighPerformanceScripts;
