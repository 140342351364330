import locales from "@/i18n/locals.json";
import en from "./en.json";
import es from "./es.json";
import data from "./data.json";

export default {
    [locales.ENGLISH]: { ...en },
    [locales.SPANISH]: { ...es },
    ["data"]:{...data},
};
