import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "@/redux/store";

export interface ICookieState {
    acceptedAll: boolean
}

export const initialState: ICookieState = {acceptedAll: true};

const cookieSlice = createSlice({
    name: "cookies",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCookieState: (state, action: PayloadAction<boolean>) => {
            state.acceptedAll = action.payload;
        },
    },
});

export const {setCookieState} = cookieSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getCookieState = (state: RootState) => state.cookies;

export default cookieSlice.reducer;